import React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"

const Articler = ({ data }) => {
  return (
    <Layout>
      <Seo title="Artikler" />

      <section className="blog-main">
        <div className="container-fluid">
          <h1>Artikler</h1>
          <div className="row">
            {data?.allWpPost?.nodes?.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <Link to={item.uri} className="article_tile">
                  <div
                    className="article_tile-thumb"
                    style={{
                      backgroundImage: `url(${item?.featuredImage?.node?.localFile?.publicURL})`,
                    }}
                  ></div>
                  <div
                    className="article_tile-header"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <div
                    className="article_tile-desc"
                    dangerouslySetInnerHTML={{
                      __html: item.excerpt,
                    }}
                  />
                  <span className="btn_custom">Les mer</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        content
        excerpt
        uri
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Articler
